@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1730947843112') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e646";
}

.icon-zuojiantou:before {
  content: "\e64e";
}

.icon-youjiantou:before {
  content: "\e678";
}

.icon-zuojiantou-:before {
  content: "\e632";
}

.icon-youjiantou1:before {
  content: "\e60b";
}

.icon-zuojiantou1:before {
  content: "\e686";
}

