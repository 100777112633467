@media screen and (min-width: 1280px) {
    .sf_footer_wrap {
        width: 100%;
        height: 200px;
        background: rgba(6, 7, 8, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .sf_footer_c_box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: fit-content;
            color: #fff;
            text-align: center;
            div {
                width: 100%;
            }
            .sf_footer_c_logo1 {
                margin-bottom: 34px;
            }
            .sf_footer_c_logo2 {
                margin-bottom: 12px;
            }
            .sf_footer_c_text {
                margin-bottom: 6px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-transform: capitalize;
                color: #FFFFFF;
            }
            .sf_footer_c_box_line {
                width: 100px;
                height: 6px;
                background: rgba(78, 115, 248, 0.8);
                border-radius: 2px;
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .sf_footer_wrap {
        width: 100%;
        height: 180px;
        background: rgba(6, 7, 8, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .sf_footer_c_box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: fit-content;
            color: #fff;
            text-align: center;
            div {
                width: 100%;
            }
            .sf_footer_c_logo1 {
                margin-bottom: 20px;
            }
            .sf_footer_c_logo2 {
                margin-bottom: 16px;
            }
            .sf_footer_c_text {
                margin-bottom: 6px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-transform: capitalize;
                color: #FFFFFF;
            }
            .sf_footer_c_box_line {
                width: 100px;
                height: 6px;
                background: rgba(78, 115, 248, 0.8);
                border-radius: 2px;
            }
        }
    }
}