.sf_alert {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 200px;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(9px);
    border-radius: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    transition: ease-in-out 1s;
}
.sf_alert_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // background: rgba(0, 0, 0, .9);
    z-index: 9999;
}