@media screen and (min-width: 1280px) {
    .sf_header_container {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        height: 50px;
        background: rgba(17, 18, 19, 0.9);
        backdrop-filter: blur(9px);
        z-index: 999;
        .sf_header_center_logo {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
        .sf_header_container_box {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            .sf_header_left {
                display: flex;
                align-items: center;
                line-height: 50px;
                padding-left: 60px;
                gap: 50px;
                .sf_header_left_item {
                    cursor: pointer;
                }
                .sf_header_left_item_menu {
                    font-size: 14px;
                    font-weight: 700;
                    color: #F6F7F8;
                    letter-spacing: 0.01em;
                }
            }
            .sf_header_right {
                display: flex;
                align-items: center;
                line-height: 50px;
                padding-left: 60px;
                gap: 50px;
                .sf_header_right_item {
                    font-size: 14px;
                    font-weight: 700;
                    color: #F6F7F8;
                    letter-spacing: 0.01em;
                    cursor: pointer;
                }
                .sf_header_lang_btn {
                    width: 80px;
                    height: 26px;
                    background: #222;
                    border-radius: 26px;
                    margin-right: 40px;
                    display: flex;
                    align-items: center;
                    padding: 4px;
                    box-sizing: border-box;
                    justify-content: center;
                    div {
                        display: block;
                        flex: 1;
                        line-height: 18px;
                        text-align: center;
                        font-size: 12px;
                        color: #999;
                        cursor: pointer;
                        &.sf_header_lang_btn_active {
                            font-weight: bold;
                            color: #fff;
                            background: rgb(51, 51, 51);
                            border-radius: 18px;
                        }
                    }
                }
                .sf_header_right_item_color {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .sf_header_right_item_text {
                        display: block;
                        background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
                        -webkit-background-clip: text;
                        color: transparent;
                        position: relative;
                        z-index: 99;
                        font-size: 14px;
                        font-weight: 700;
                        cursor: pointer;
                    }
                    .sf_header_right_item_color_border {
                        position: absolute;
                        height: 1px;
                        background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
                        width: 100%;
                        bottom: 12px;
                        background-clip: border-box;
                        .sf_header_right_item_color_border_inside {
                            position: absolute;
                            width: 100%;
                            border-bottom: 1px dashed rgba(17, 18, 19, 0.9);
                            backdrop-filter: blur(9px);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .sf_header_container {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        background: rgba(17, 18, 19, 0.9);
        -webkit-backdrop-filter: blur(9px);
        backdrop-filter: blur(9px);
        z-index: 9999;
        padding: 0 26px;
        box-sizing: border-box;
        font-size: 12px;
        
        .sf-header-menu-icon {
            width: 60px;
            position: relative;
            z-index: 999;
            .sf-header-menu-icon-1 {
                width: 15px;
                height: 2.5px;
                margin-bottom: 4px;
                background-color: #fff;
                border-radius: 6px;
                transition: ease-in .5s;
                &.sf-header-menu-icon-1-open {
                    width: 22px;
                }
            }
            .sf-header-menu-icon-2 {
                width: 13px;
                height: 2.5px;
                margin-bottom: 4px;
                background-color: #fff;
                transition: ease-in .5s;
                border-radius: 6px;
                &.sf-header-menu-icon-2-open {
                    width: 19px;
                }
            }
            .sf-header-menu-icon-3 {
                width: 11px;
                height: 2.5px;
                background-color: #fff;
                border-radius: 6px;
                transition: ease-in .5s;
                &.sf-header-menu-icon-3-open {
                    width: 16px;
                }
            }
        }
    }
    .sf_header_center_logo {
        width: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .sf_header_container_outBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }
    .sf_header_lang_btn {
        width: 72px;
        height: 26px;
        border-radius: 24px;
        background: #222222;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        div {
            display: block;
            flex: 1;
            line-height: 18px;
            text-align: center;
            font-size: 10px;
            color: #999;
            &.sf_header_lang_btn_active {
                font-weight: bold;
                color: #fff;
                background: rgb(51, 51, 51);
                border-radius: 18px;
            }
        }
    }
    .sf_header_container_box {
        touch-action: none;
        position: fixed;
        top: 0;
        left: 0;
        padding: 80px 30px 0;
        width: 100%;
        height: 100vh;
        background: #000;
        background: rgba(22, 23, 24, 0.9);
        box-sizing: border-box;
        backdrop-filter: blur(6px);
        z-index: 9999;
        transition: ease-in-out .5s;
        overflow: auto;
        .sf_header_container_box_inside {
            position: relative;
            height: 100%;
            min-height: 600px;
        }
    }
    .sf_header_menu_item {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        padding: 17px 0;
        border-bottom: 1px dashed #333;
        .sf_header_menu_item_svg {
            margin-right: 10px;
        }
    }
    .sf_header_menu_item_first {
        padding: 15px 0 0 30px;
        font-size: 14px;
        .sf_header_left_item  {
            line-height: 17px;
            margin-bottom: 17px;
            letter-spacing: 0.01em;
            white-space: nowrap;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-weight: 400;
            }
        }
    }
    .head_menu_footer {
        position: absolute;
        bottom: 56px;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        .sf_footer_c_logo2 {
            margin-bottom: 15px;
        }
        .sf_footer_c_text {
            margin-bottom: 6px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        .sf_footer_c_box_line {
            width: 100px;
            height: 6px;
            background: rgba(78, 115, 248, 0.8);
            border-radius: 2px;
        }
    }
}