@media screen and (min-width: 1280px) {
    .advert_dialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(22, 23, 24, 0.9);
        backdrop-filter: blur(6px);
        z-index: 999;
        touch-action: none;
        .advert_dialog_box {
            width: 620px;
            height: 380px;
            border-radius: 30px;
            background: #222222;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: ease-in-out 1s;
            user-select: none;
            z-index: 999;
            img {
                width: 100%;
                height: 300px;
                border-radius:30px 30px 0 0;
            }
            .advert_dialog_close {
                width: 30px;
                height: 30px;
                background: rgba(34, 34, 34, 0.3);
                border-radius: 4px;
                position: absolute;
                right: 20px;
                top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .advert_dialog_content {
                padding: 20px 30px;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
            }
            .advert_dialog_article {

                position: relative;
                top: -8px;
            }
            .advert_dialog_content_top {
                display: flex;
                font-weight: bold;
                color: #Fff;
                align-items: center;
                font-size: 14px;
                span {
                    font-size: 20px;
                }
            }
            .advert_dialog_content_bottom {
                color: #999;
                font-size: 14px;
            }
            .advert_dialog_btn {
                width: 160px;
                height: 42px;
                background: #4E73F8;
                color: #fff;
                font-weight: bold;
                border-radius: 42px;
                text-align: center;
                cursor: pointer;
                line-height: 42px;
                user-select: none;
                font-size: 14px;
                position: relative;
                top: -4px;
            }
        }
    }
}
@media screen and (max-width: 1281px) {
    .advert_dialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(22, 23, 24, 0.9);
        backdrop-filter: blur(6px);
        z-index: 9999;
        touch-action: none;
        .advert_dialog_box {
            width: calc(100% - 30px);
            height: 400px;
            border-radius: 30px;
            background: #222222;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            transition: ease-in-out 1s;
            user-select: none;
            img {
                width: 100%;
                height: 240px;
                border-radius:30px 30px 0 0;
            }
            .advert_dialog_close {
                width: 30px;
                height: 30px;
                background: rgba(34, 34, 34, 0.3);
                border-radius: 4px;
                position: absolute;
                right: 20px;
                top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .advert_dialog_content {
                padding: 24px;
                font-size: 12px;
                display: flex;
                flex-wrap: wrap;
            }
            .advert_dialog_article {
                width: 100%;
                text-align: center;
                line-height: 20px;
                margin-bottom: 18px;
                min-width: 352px;
                position: relative;
                transform: translate(-50%, 0px);
                left: 50%
            }
            .advert_dialog_content_top {
                width: 100%;
                display: flex;
                font-weight: bold;
                color: #Fff;
                justify-content: center;
                align-items: center;
                height: 16px;
                span {
                    font-size: 18px;
                }
                margin-bottom: 5px;
                letter-spacing: 0.01em;
                &.advert_dialog_content_top_cn {
                    
                    text-align: justify;
                    text-justify: inter-word;
                    svg {
                        position: relative;
                        top: -2px;
                    }
                    p {
                        width: 300px;
                        height: 24px;
                        text-align: justify;
                        text-justify: inter-word;
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
                
            }
            .advert_dialog_content_bottom {
                color: #999;
                letter-spacing: 0.01em;
                width: 347px;
                &.advert_dialog_content_bottom_cn {
                    height: 20px;
                    width: 319px;
                    text-align: justify;
                    margin: 0 auto;
                    text-justify: inter-word;
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
            .advert_dialog_btn {
                width: 100%;
                height: 45px;
                background: #4E73F8;
                color: #fff;
                font-weight: bold;
                border-radius: 45px;
                text-align: center;
                cursor: pointer;
                line-height: 45px;
                user-select: none;
                font-size: 14px;
            }
        }
    }
}