@media screen and (min-width: 1280px) {
    .sf_com_head {
        width: 100%;
        height: 360px;
        position: relative;
        background: url('../../img/homepage/background.jpg') no-repeat center / cover;
        .sf_com_head_logo {
            width: 120px;
            height: 120px;
            backdrop-filter: blur(5px);
            border-radius: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            z-index: 9;
        }
        .sf_com_head_logo_bottom {
            position: absolute;
            left: 50%;
            bottom: 30px;
            z-index: 9;
            text-align: center;
            transform: translate(-50%, 0);
            color: #fff;
            .sf_com_head_logo_bottom_text {
                margin-bottom: 6px;
                padding-top: 12px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-transform: capitalize;
            }
            .sf_com_head_logo_bottom_line {
                background: #4E73F8CC;
                height: 6px;
                border-radius: 6px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .sf_com_head {
        width: 100%;
        height: 260px;
        position: relative;
        background: url('../../img/homepage/background.jpg') no-repeat center / cover;
        .sf_com_head_logo {
            width: 72px;
            height: 72px;
            backdrop-filter: blur(5px);
            border-radius: 15px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            z-index: 9;
        }
        .sf_com_head_logo_bottom {
            position: absolute;
            left: 50%;
            bottom: 20px;
            z-index: 9;
            text-align: center;
            transform: translate(-50%, 0);
            color: #fff;
            .sf_com_head_logo_bottom_text {
                margin-bottom: 3px;
                padding-top: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                text-transform: capitalize;
            }
            .sf_com_head_logo_bottom_line {
                background: #4E73F8CC;
                height: 4px;
                border-radius: 4px;
            }
            .sf_com_head_logo_bottom_logo {
                height: 12px;
                line-height: 12px;
            }
        }
    }
}