* {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: "Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Helvetica Neue", Arial, sans-serif;
}
*:focus {
    outline: none;
}
.sf_wrapper {
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background: #161718;
    color: #fff;
}
.colorful-text {
    background: linear-gradient(90deg, #FF0000 0%, #FF6400 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
    -webkit-background-clip: text;
    // color: transparent;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.pt-100 {
    padding-top: 100px;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-200 {
    margin-bottom: 200px;
}
.dashed_line {
    width: 100%;
    border-bottom: 1px dashed #333;
    border-bottom-width: calc(1px / 2);
}
.dashed_line_333 {
    width: 100%;
    border-bottom: 1px dashed #333;
    border-bottom-width: calc(1px / 2);
}
.pb-200 {
    padding-bottom: 200px;
}
.mb-40 {
    margin-bottom: 40px;
}
.pt-40 {
    padding-top: 40px;
}
.mb-80 {
    margin-bottom: 80px;
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}
@media screen and (min-width: 1280px) {
    .container_box {
        width: 1280px;
        margin: 0 auto;
    }
}


.homepage_c_bottom {
    position: fixed;
    left: 50%;
    bottom: 110px;
    transform: translate(-50%, 0);
    width: 200px;
    height: 50px;
    border-radius: 30px;
    text-align: center;
    line-height: 50px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);
    z-index: 99;
    transition: ease-in-out .5s;
    .homepage_c_bottom_text_bg {
        background: linear-gradient(90deg, #FF0000 0%, #FF6400 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 16px;
        font-weight: 700;
    }
}