@media screen and (min-width: 1280px) {
    .homepage_box {
        .homepage_top_img_box {
            width: 100%;
            height: 100vh;
            background: url('../img/homepage/background.jpg') no-repeat center / cover;
            position: relative;
            .homepage_top_img_center_box {
                width: 300px;
                height: 300px;
                backdrop-filter: blur(5px);
                border-radius: 60px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            .homepage_top_img_bottom_box {
                width: 132px;
                text-align: center;
                position: absolute;
                bottom: 80px;
                left: 50%;
                transform: translate(-50%, 0);
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                .homepage_top_img_bottom_text {
                    padding: 20px 0 6px 0;
                    line-height: 21px;
                }
                .homepage_top_img_bottom_box_line {
                    width: 132px;
                    height: 6px;
                    border-radius: 2px;
                    background: #4E73F8CC;
                }
            }
        }
        .homepage_container {
            width: 1280px;
            margin: 0 auto;
            padding-top: 120px;
            overflow: hidden;
            .homepage_c_item {
                &.homepage_c_item_first {
                    text-align: center;
                    .homepage_c_item_first_logo {
                        margin-bottom: 75px;
                    }
                    .homepage_c_item_article {
                        width: 720px;
                        margin: 0 auto 150px;
                        font-size: 16px;
                    }
                    .homepage_c_item_title {
                        margin: 0 auto 40px;
                    }
                }
                .homepage_c_item_title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: 0.01em;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    width: fit-content;
                    margin-bottom: 50px;
                    .sf_title_textLine {
                        margin-left: 10px;
                        position: relative;
                        &::after {
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            content: "";
                            background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
                        }
                    }
                }
                .homepage_c_item_content_box {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                }
                .homepage_c_item_article {
                    width: 800px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 32px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                }
                .homepage_c_item_line {
                    padding-top: 80px;
                    margin-bottom: 100px;
                    border-bottom: 1px dashed #333333
                }
                .homepage_c_item_content_more_btn {
                    width: 240px;
                    height: 60px;
                    box-sizing: border-box;
                    background: rgba(255, 255, 255, 0.05);
                    border: 1px solid #FFFFFF;
                    border-radius: 30px;
                    text-transform: capitalize;    
                    font-size: 16px;     
                    color: #fff;
                    text-align: center;
                    user-select: none;
                    cursor: pointer;
                    transition: .1s ease-in-out;
                    &:hover {
                        background: rgba(255, 255, 255, .2);
                    }
                    .homepage_c_item_content_more_btn_title_big {
                        padding-top: 8px;
                        margin-bottom: 3px;
                        font-weight: 700;
                    }
                    .homepage_c_item_content_more_btn_title_small {
                        font-size: 12px;
                    }           
                }
                .homepage_c_item_type2_item1 {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 60px;
                    .homepage_c_item_type2_item1_item {
                        width: 280px;
                        height: 132px;
                        color: #FFFFFF;
                        letter-spacing: 0.01em;
                        font-style: normal;
                        .homepage_c_item_type2_item1_item_title {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 30px;
                            text-transform: capitalize;
                        }
                        .homepage_c_item_type2_item1_item_content {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }
                    &.homepage_type3 {
                        margin-bottom: 40px;
                    }
                }
                .homepage_c_item_type2_item2_wrap {
                    position: relative;
                    margin-bottom: 60px;
                    .homePage_type2_btn_box {
                        position: absolute;
                        display: flex;
                        left: 50%;
                        bottom: -95px;
                        transform: translate(-50%, 0);
                        gap: 20px;
                        .homePage_type2_btn {
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            background: rgba(255, 255, 255, 0.3);
                            border-radius: 50%;
                            color: #fff;
                            font-weight: bolder;
                            cursor: pointer;
                            &.homePage_type2_btn_right {
                                box-sizing: border-box;
                                padding-left: 2px;
                            }
                            &.cursor_no-drop {
                                cursor: no-drop;
                                opacity: .5;
                            }
                        }
                    }
                }
                .homepage_c_item_type2_item2 {
                    width: 100%;
                    height: 300px;
                    overflow: hidden;
                    position: relative;
                    .homepage_c_item_type2_item2_Box {
                        width: fit-content;
                        gap: 40px;
                        white-space: nowrap;
                        display: flex;
                        height: 300px;
                        transition: 1s ease-in-out;
                        position: absolute;
                        top: 0;
                        left: 0;
                        li {
                            width: 400px;
                            height: 300px;
                            border-radius: 20px;
                            float: left;
                            position: relative;
                            img {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                object-fit: fill;
                                border-radius: 20px;
                            }
                            .homepage_c_item_type2_item2_tag {
                                position: absolute;
                                width: 120px;
                                height: 30px;
                                left: 20px;
                                top: 20px;
                                box-sizing: border-box;
                                border-radius: 20px;
                                background: rgba(255, 255, 255, 0.5);
                                border: 1px solid rgba(255, 255, 255, 1);
                                z-index: 99;
                                text-align: center;
                                line-height: 28px;
                                color: rgba(51, 51, 51, 1);
                                font-size: 12px;
                                font-weight: 700;
                                backdrop-filter: blur(6px);
                            }
                        }
                    }
                    /* 定义滚动条整体样式 */
                    &::-webkit-scrollbar {
                        width: 10px; /* 设置滚动条的宽度 */
                    }
                    
                    /* 定义滚动条滑块的样式 */
                    &::-webkit-scrollbar-thumb {
                        background: #ccc; /* 设置滑块的背景颜色 */
                        border-radius: 10px; /* 设置滑块的圆角 */
                    }
                    
                    /* 定义滚动条轨道的样式 */
                    &::-webkit-scrollbar-track {
                        background: #CCCCCC1A; /* 设置轨道的背景颜色 */
                    }
                }
                .homepage_c_item_type2 {
                    display: flex;
                    justify-content: space-between;
                    .homepage_c_item_type2_item {
                        width: 620px;
                        height: 900px;
                        box-sizing: border-box;
                        padding: 40px 50px;
                        background: #222222;
                        border-radius: 30px;
                        color: #fff;
                        letter-spacing: 0.01em;
                        font-style: normal;
                        .homepage_c_item_type2_item_title {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 26px;
                            text-transform: capitalize;
                            margin-bottom: 5px;
                            &.homepage_c_item_type2_item_title_small {
                                font-size: 14px;
                                margin-bottom: 30px;
                            }
                        }
                        .homepage_c_item_type2_item_content {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                            margin-bottom: 30px;
                        }
                        .homepage_c_item_type2_item_imglist_tag {
                            position: absolute;
                            width: 120px;
                            height: 30px;
                            left: 20px;
                            top: 20px;
                            box-sizing: border-box;
                            border-radius: 20px;
                            background: rgba(255, 255, 255, 0.5);
                            border: 1px solid rgba(255, 255, 255, 1);
                            z-index: 99;
                            text-align: center;
                            line-height: 28px;
                            color: rgba(51, 51, 51, 1);
                            font-size: 12px;
                            font-weight: 700;
                            backdrop-filter: blur(6px);
                        }
                        .homepage_c_item_type2_item_imglist {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 30px;
                            .homepage_c_item_type2_item_imglist_box {
                                width: 250px;
                                .homepage_c_item_type2_item_imglist_title {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 10px;
                                    text-align: center;
                                    letter-spacing: 0.01em;
                                    color: #FFFFFF;
                                    margin-bottom: 10px;
                                }
                                img {
                                    width: 250px;
                                    height: 160px;
                                    border-radius: 10px;
                                }
                            }
                        }
                        .homepage_c_item_type2_item_img {
                            width: 520px;
                            height: 380px;
                            border-radius: 10px;
                        }
                    }
                }
                .homepage_c_item_type3 {
                    display: flex;
                    justify-content: space-between;
                    color: #fff;
                    .homepage_c_item_type3_left {
                        width: 420px;
                        font-style: normal;
                        color: #FFFFFF;
                        .homepage_c_item_type3_left_title {
                            letter-spacing: 0.01em;
                            text-transform: capitalize;
                            line-height: 30px;
                            font-weight: 700;
                            font-size: 18px;
                        }
                        .homepage_c_item_type3_left_content {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                            margin-bottom: 30px;
                            letter-spacing: 0.01em;
                        }
                    }
                    .homepage_c_item_type3_right {
                        width: 780px;
                        height: 640px;
                        border-radius: 30px;
                        background: #222222;
                        padding: 40px 50px;
                        box-sizing: border-box;
                        .homepage_c_item_type3_right_item_title {
                            margin-bottom: 10px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            font-family: 'Courier New';
                        }
                        .homepage_c_item_type3_right_item_table_title {
                            display: flex;
                            width: 100%;

                            margin-bottom: 10px;
                            .homepage_c_item_type3_right_item_table_title_item {
                                font-family: 'Courier New';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 24px;
                                text-transform: capitalize;
                                color: #FFFFFF;
                                flex: 1;
                            }
                            .homepage_type3_table_title_right {
                                text-align: right;
                            }
                        }
                        .homepage_3_line {
                            border-bottom: 1px dashed #ffffff50;
                            margin-bottom: 8px;
                            border-bottom-width: calc(1px / 2);
                        }
                        .homepage_3_data_box {
                            margin-bottom: 18px;
                            .homepage_3_data_item {
                                display: flex;
                                .homepage_3_tableData_data {
                                    flex: 1;
                                    font-family: 'Courier New';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 24px;
                                    &.homepage_3_tableData_data_right {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                        .homepage_3_img_box {
                            display: flex;
                            justify-content: 
                            space-between;
                            &.mb-40 {
                                margin-bottom: 40px;
                            }
                            img {
                                width: 160px;
                                height: 96px;
                                &.homepage_3_img_border_left {
                                    border-radius: 10px 0 0 10px;
                                }
                                &.homepage_3_img_border_right {
                                    border-radius: 0 10px 10px 0;
                                }
                            }
                        }
                    }
                }
                .homepage_type3_box {
                    width: 100%;
                    background: #222222;
                    border-radius: 30px;
                    padding: 50px;
                    box-sizing: border-box;
                    margin-bottom: 200px;
                    color: #fff;
                    .homepage_type3_box_title {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: 0.01em;
                        text-transform: capitalize;
                        margin-bottom: 40px;
                    }
                    .homepage_type3_box_1_c {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 26px;
                        letter-spacing: 0.01em;
                        margin-bottom: 30px;
                    }
                    .homepage_type3_box_1_b {
                        display: flex;
                        justify-content: space-between;
                        .homepage_type3_box_1_b_i {
                            width: 370px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.01em;
                            text-align: center;
                            .homepage_type3_box_1_b_i_title {
                                margin-bottom: 10px;
                            }
                            img {
                                width: 370px;
                                height: 240px;
                                border-radius: 10px;
                            }
                        }
                    }
                    .homepage_type3_box_3 {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 48px;
                        align-items: center;
                        .homepage_type3_box_3_left {
                            font-family: 'Courier New';
                            width: 520px;
                            .homepage_type3_box_3_label {
                                display: flex;
                                font-family: 'Courier New';
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 24px;
                                text-align: left;
                                .homepage_type3_box_3_label_c {
                                    font-family: 'Courier New';
                                    font-weight: 400;
                                    margin-left: 8px;
                                }
                                .homepage_type3_box_3_label_t {
                                    font-family: 'Courier New';
                                    text-transform: capitalize;
                                }
                            }
                            .homepage_type3_box_3_left_title {
                                font-family: 'Courier New';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                                text-transform: capitalize;
                                margin-bottom: 20px;
                            }
                            .homepage_type3_box_3_tooltip {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 13px;
                                color: #666666;
                            }
                        }
                        .homepage_type3_box_3_right {
                            width: 620px;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            height: 420px;
                            overflow: hidden;
                            img {
                                width: 300px;
                                height: 200px;
                                border-radius: 10px;
                                margin: 0 20px 20px 0;
                                &:nth-child(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1281px) {
    .homepage_box {
        .homepage_top_img_box {
            width: 100%;
            height: 100vh;
            background: url('../img/homepage/background.jpg') no-repeat center / cover;
            position: relative;
            .homepage_top_img_center_box {
                width: 180px;
                height: 180px;
                backdrop-filter: blur(5px);
                border-radius: 40px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            .homepage_top_img_bottom_box {
                // width: 132px;
                width: fit-content;
                text-align: center;
                position: absolute;
                bottom: 68px;
                left: 50%;
                transform: translate(-50%, 0);
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                .homepage_top_img_bottom_box_svg {
                    margin-bottom: 6px;
                }
                .homepage_top_img_bottom_text {
                    width: 100%;
                    font-size: 12px;
                    margin-bottom: 4px;
                    text-align: center;
                }
                .homepage_top_img_bottom_box_line {
                    width: 100px;
                    height: 6px;
                    background: rgba(78, 115, 248, 0.8);
                    border-radius: 2px;
                }
            }
        }
        .homepage_container {
            width: 100%;
            margin: 0 auto;
            padding: 64px 26px 100px;
            overflow: hidden;
            box-sizing: border-box;
            .homepage_c_item {
                padding-top: 60px;
                &.homepage_c_item_first {
                    text-align: center;
                    padding-top: 0;
                    .homepage_c_item_first_logo {
                        margin-bottom: 20px;
                        width: 213px;
                        height: 54px;
                    }
                    .homepage_c_item_article {
                        width: 100%;
                        margin: 0 auto 20px;
                        font-size: 15px;
                        line-height: 23px;
                        box-sizing: border-box;
                    }
                    .homepage_c_item_title {
                        margin: 0 auto 30px;
                        font-size: 16px;
                    }
                }
                .homepage_c_item_title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    letter-spacing: 0.01em;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    width: fit-content;
                    margin-bottom: 10px;
                    .sf_title_textLine {
                        margin-left: 10px;
                        position: relative;
                        &::after {
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            content: "";
                            background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
                        }
                    }
                }
                .homepage_c_item_content_box {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 22px;
                }
                .homepage_c_item_article {
                    width: 380px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 23px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                }
                .homepage_c_item_line {
                    padding-top: 60px;
                    border-bottom: 1px dashed #333333
                }
                .homepage_c_item_content_more_btn {
                    width: 100%;
                    height: 52px;
                    box-sizing: border-box;
                    background: rgba(255, 255, 255, 0.05);
                    border: 1px solid #FFFFFF;
                    border-radius: 30px;
                    text-transform: capitalize;    
                    font-size: 16px;     
                    color: #fff;
                    text-align: center;
                    user-select: none;
                    box-sizing: border-box;
                    padding: 5px 0 0;
                    .homepage_c_item_content_more_btn_title_big {
                        margin-bottom: 3px;
                        font-weight: 700;
                        line-height: 20px;
                    }
                    .homepage_c_item_content_more_btn_title_small {
                        font-size: 12px;
                    }           
                }
                .homepage_c_item_type2 {
                    padding-top: 10px;
                }
                .homepage_c_item_type2_item1 {
                    margin-bottom: 30px;
                    .homepage_c_item_type2_item1_item {
                        width: 100%;
                        height: auto;
                        color: #FFFFFF;
                        letter-spacing: 0.01em;
                        font-style: normal;
                        margin-bottom: 20px;
                        box-sizing: border-box;
                        .homepage_c_item_type2_item1_item_title {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 24px;
                            text-transform: capitalize;
                            margin-bottom: 5px;
                        }
                        .homepage_c_item_type2_item1_item_content {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                    &.homepage_type3 {
                        margin-bottom: 30px;
                    }
                }
                .homepage_c_item_type2_item2_wrap {
                    position: relative;
                    margin: 0 -26px 60px;
                    .adm-swiper-track {
                        padding:0 13px 0 26px;
                        box-sizing: border-box;
                    }
                    .adm-swiper-indicator {
                        display: none;
                    }
                    .adm-swiper-slide {
                        padding-right: 20px;
                        box-sizing: border-box;
                    }
                    .adm-swiper-item {
                        // height: 255px;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 15px;
                        }
                    }
                    .homepage_c_item_type2_item2_tag {
                        position: absolute;
                        width: 110px;
                        height: 30px;
                        left: 13px;
                        top: 13px;
                        box-sizing: border-box;
                        border-radius: 20px;
                        background: rgba(255, 255, 255, 0.5);
                        border: 1px solid rgba(255, 255, 255, 1);
                        z-index: 99;
                        text-align: center;
                        line-height: 28px;
                        color: rgba(51, 51, 51, 1);
                        font-size: 12px;
                        font-weight: 700;
                        backdrop-filter: blur(6px);
                    }
                }
                .homepage_c_item_type2 {
                    // width: 100%;
                    overflow-x: scroll;
                    margin: 0 -26px 60px;
                    
                    .adm-swiper-track {
                        padding:0 13px 0 26px;
                        box-sizing: border-box;
                    }
                    .adm-swiper-indicator {
                        display: none;
                    }
                    
                    .adm-swiper-slide {
                        padding-right: 20px;
                        box-sizing: border-box;
                    }
                    .homepage_c_item_type2_item {
                        width: 100%;
                        height: auto;
                        box-sizing: border-box;
                        padding: 30px 20px;
                        background: #222222;
                        border-radius: 20px;
                        color: #fff;
                        letter-spacing: 0.01em;
                        font-style: normal;
                        &:last-child {
                            margin-right: 26px;
                        }
                        .homepage_c_item_type2_item_title {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 24px;
                            text-transform: capitalize;
                            margin-bottom: 5px;
                            &.homepage_c_item_type2_item_title_small {
                                font-size: 11px;
                                margin-bottom: 15px;
                            }
                        }
                        .homepage_c_item_type2_item_content {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            margin-bottom: 18px;
                        }
                        .homepage_c_item_type2_item_imglist {
                            .homepage_c_item_type2_item_imglist_box {
                                width: 100%;
                                margin-bottom: 15px;
                                .homepage_c_item_type2_item_imglist_title {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 10px;
                                    text-align: center;
                                    letter-spacing: 0.01em;
                                    color: #FFFFFF;
                                    margin-bottom: 10px;
                                }
                                img {
                                    width: 100%;
                                    // height: 192px;
                                    border-radius: 10px;
                                }
                            }
                        }
                        .homepage_c_item_type2_item_imglist_tag {
                            position: absolute;
                                width: 110px;
                                height: 30px;
                                left: 13px;
                                top: 13px;
                                box-sizing: border-box;
                                border-radius: 20px;
                                background: rgba(255, 255, 255, 0.5);
                                border: 1px solid rgba(255, 255, 255, 1);
                                z-index: 9;
                                text-align: center;
                                line-height: 28px;
                                color: rgba(51, 51, 51, 1);
                                font-size: 12px;
                                font-weight: 700;
                                backdrop-filter: blur(6px);
                        }
                        .homepage_c_item_type2_item_img {
                            width: 100%;
                            height: 220px;
                            border-radius: 10px;
                        }
                    }
                }
                
                .homepage_c_item_type3_right_item {
                    &:first-child {
                        margin-bottom: 25px;
                    }
                }
                .homepage_c_item_type3 {
                    color: #fff;
                    .homepage_c_item_type3_left {
                        width: 100%;
                        font-style: normal;
                        color: #FFFFFF;
                        .homepage_c_item_type3_left_title {
                            letter-spacing: 0.01em;
                            text-transform: capitalize;
                            line-height: 24px;
                            font-weight: 700;
                            font-size: 18px;
                            margin-bottom: 5px;
                        }
                        .homepage_c_item_type3_left_content {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            margin-bottom: 30px;
                            letter-spacing: 0.01em;
                        }
                    }
                    .homepage_c_item_type3_right {
                        width: 100%;
                        height: auto;
                        border-radius: 20px;
                        background: #222222;
                        padding: 30px 15px;
                        box-sizing: border-box;
                        margin-bottom: 40px;
                        .homepage_c_item_type3_right_item_title {
                            margin-bottom: 5px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: 'Courier New';
                        }
                        .homepage_c_item_type3_right_item_table_title {
                            display: flex;
                            width: 100%;
                            margin-bottom: 5px;
                            .homepage_c_item_type3_right_item_table_title_item {
                                font-family: 'Courier New';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 9px;
                                line-height: 18px;
                                text-transform: capitalize;
                                white-space: nowrap;
                                color: #FFFFFF;
                                flex: 1;
                                margin-right: 5px;
                                &:last-child {
                                    width: 20px;
                                    flex: none;
                                }
                            }
                            .homepage_type3_table_title_right {
                                text-align: right;
                            }
                        }
                        .homepage_3_line {
                            border-bottom: 1px dashed #ffffff50;
                            margin-bottom: 8px;
                            border-bottom-width: calc(1px / 2);
                        }
                        .homepage_3_data_box {
                            margin-bottom: 9px;
                            .homepage_3_data_item {
                                display: flex;
                                .homepage_3_tableData_data {
                                    flex: 1;
                                    font-family: 'Courier New';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 9px;
                                    line-height: 18px;
                                    margin-right: 5px;
                                    white-space: nowrap;
                                    &:last-child {
                                        width: 20px;
                                        flex: none;
                                    }
                                    &.homepage_3_tableData_data_right {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                        .homepage_3_img {

                            &.homepage_3_img_border_left {
                                border-radius: 5px 0 0 5px;
                            }
                            &.homepage_3_img_border_right {
                                border-radius: 0 5px 5px 0;
                            }
                        }
                        .homepage_3_img_box {
                            display: flex;
                            justify-content: 
                            space-between;
                            gap: 5px;
                            &.mb-30 {
                                margin-bottom: 15px !important;
                            }
                            img {
                                width: calc(25% - 5px);
                                // height: 50px;
                            }
                        }
                    }
                }
                .homepage_type3_box {
                    width: 100%;
                    background: #222222;
                    border-radius: 30px;
                    padding: 30px 20px;
                    box-sizing: border-box;
                    margin-bottom: 60px;
                    color: #fff;
                    .homepage_type3_box_title {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: 0.01em;
                        text-transform: capitalize;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    .homepage_type3_box_1_c {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 22px;
                        letter-spacing: 0.01em;
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 22px;
                        }
                    }
                    .homepage_type3_box_1_b {
                        .homepage_type3_box_1_b_i {
                            width: 100%;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0.01em;
                            text-align: center;
                            margin-bottom: 20px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .homepage_type3_box_1_b_i_title {
                                margin-bottom: 10px;
                            }
                            img {
                                width: 100%;
                                // height: 220px;
                                border-radius: 10px;
                            }
                        }
                    }
                    .homepage_type3_box_3 {
                        margin-bottom: 40px;
                        .homepage_type3_box_3_left {
                            font-family: 'Courier New';
                            .homepage_type3_box_3_label {
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 20px;
                                text-align: left;
                                .homepage_type3_box_3_label_c {
                                    font-family: 'Courier New';
                                    font-weight: 400;
                                }
                                .homepage_type3_box_3_label_t {
                                    font-family: 'Courier New';
                                    text-transform: capitalize;
                                }
                            }
                            .homepage_type3_box_3_left_title {
                                font-family: 'Courier New';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 13px;
                                line-height: 20px;
                                text-transform: capitalize;
                                margin-bottom: 12px;
                            }
                            .homepage_type3_box_3_tooltip {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 13px;
                                color: #666666;
                                margin-bottom: 30px;
                            }
                        }
                        .homepage_type3_box_3_right {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            overflow: hidden;
                            gap: 12px;
                            img {
                                width: calc((100% - 12px) / 2);
                                // height: 110px;
                                border-radius: 10px;
                                &:nth-child(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}