@media screen and (min-width: 1280px) {
    .advert {
        height: 36px;
        background: #4E73F8;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        p {
            width: fit-content;
            margin: 0 auto;
            line-height: 36px;
            font-size: 14px;
            color: #fff;
            user-select: none;
            cursor: pointer;
            a {
                font-weight: bolder;
                color: #fff;
                text-decoration: underline;
            }
        }
        .advert_close_icon {
            cursor: pointer;
            width: 100px;
            height: 36px;
            right: 20px;
            top: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-weight: bolder;
            }
        }
    }
}
@media screen and (max-width: 1281px) {
    .advert {
        height: 80px;
        background: #4E73F8;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
        p {
            width: 260px;
            line-height: 18px;
            font-size: 12px;
            color: #fff;
            user-select: none;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            a {
                font-weight: bolder;
                color: #fff;
                text-decoration: underline;
            }
        }
        .advert_close_icon {
            cursor: pointer;
            width: 36px;
            height: 36px;
            right: 10px;
            top: 50%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(0, -50%);
            span {
                font-weight: bolder;
            }
        }
    }
}