@media screen and (min-width: 1280px) {
    .document_logo {
        text-align: center;
        margin-bottom: 80px;
    }
    .container_box {
        position: relative;
    }
    .docment_content {
        width: 720px;
        margin: 0 auto 200px;
        position: relative;
        .docment_item {
            letter-spacing: 0.01em;
            font-style: normal;
            overflow: hidden;
            .docment_item_title {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-transform: capitalize;
            }
            .docment_item_content {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
        }
        .docment_item_list {
            color: #BBB;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-decoration-line: underline;
            text-transform: capitalize;
            cursor: pointer;height: 36px;
            border-radius: 10px;
            background: #222222;
            box-sizing: border-box;
            padding: 6px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: .1s ease-in-out;
            &:hover {
                background-color: #333;
                color: #eee;
                .docment_item_list_text {
                    color: #eee;
                }
                .docment_item_list_logo {
                    background: #333;
                }
            }
        }
        .document_left_menu {
            position: absolute;
            display: flex;
            left: -280px;
            top: 0;
            width: 110px;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.01em;
            align-items: stretch;
            justify-content: flex-end;
            .document_left_menu_item {
                margin-bottom: 8px;
                cursor: pointer;
            }
            :where(.css-dev-only-do-not-override-11xg00t).ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
                color: #fff;
            }
            .document_left_menu_line_wrap {
                width: 15px;
                height: auto;
                box-sizing: border-box;
                padding: 14px 0 20px;
                position: relative;
            }
            .document_left_menu_line {
                height: 100%;
                border-right: 1px dashed #999;
                border-right-width: calc(1px  / 2);
            }
            .document_left_menu_btn {
                position: absolute;
                left: 7px;
                top: 2px;
                .document_left_menu_btn_item {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #333;
                    border: 2px solid #666;
                    &.document_left_menu_btn_item_active {
                        background: #eee;
                    }
                }
            }
            .document_left_menu_btn_item_box {
                height: 22px;
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                cursor: pointer;
            }
            &.document_left_menu_fixed {
                position: fixed;
                top: 100px;
                left: calc((100% - 1280px) / 2);
            }
        }
    }
    
}
@media screen and (max-width: 1280px) {
    .document_logo {
        text-align: center;
        margin-bottom: 25px;
    }
    .document_logo_svg {
        width: 120px;
        height: 54px;
    }
    .container_box {
        position: relative;
        padding: 30px 26px 100px;
        box-sizing: border-box;
    }
    .docment_content {
        width: 100%;
        margin: 0 auto;
        .docment_item {
            letter-spacing: 0.01em;
            font-style: normal;
            overflow: hidden;
            .docment_item_title {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-transform: capitalize;
            }
            .docment_item_title_small {
                font-size: 16px;
                line-height: 22px;
            }
            .docment_item_content {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
        }
        .docment_item_list {
            color: #BBB;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-decoration-line: underline;
            text-transform: capitalize;
            height: 36px;
            border-radius: 10px;
            background: #222222;
            box-sizing: border-box;
            padding: 6px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .docment_item_list_text {
                padding-right: 20px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
            }
            .docment_item_list_logo {
                width: 10px;
                height: 10px;
            }
            &:hover {
                background-color: #444;
                color: #eee;
                .docment_item_list_text {
                    color: #eee;
                }
                .docment_item_list_logo {
                    background: #333;
                }
            }
        }
        .document_left_menu {
            position: absolute;
            display: flex;
            left: 0;
            top: 0;
            width: fit-content;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.01em;
            align-items: stretch;
            .document_left_menu_item {
                margin-bottom: 8px;
            }
            .ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
                color: #fff;
            }
            .document_left_menu_line_wrap {
                width: 15px;
                height: auto;
                box-sizing: border-box;
                padding: 14px 0 20px;
                position: relative;
            }
            .document_left_menu_line {
                height: 100%;
                border-right: 1px dashed #999;
                border-right-width: calc(1px  / 2);
            }
            .document_left_menu_btn {
                position: absolute;
                left: 7px;
                top: 2px;
                .document_left_menu_btn_item {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #333;
                    border: 2px solid #666;
                    &.document_left_menu_btn_item_active {
                        background: #eee;
                    }
                }
            }
            .document_left_menu_btn_item_box {
                height: 22px;
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                cursor: pointer;
            }
            &.document_left_menu_fixed {
                position: fixed;
                top: 100px;
                left: calc((100% - 1280px) / 2);
            }
        }
    }
    
}