@media screen and (min-width: 1280px) {
    .contact_us_title {
        text-align: center;
    }
    .contact_us_wrap {
        overflow: hidden;
    }
    
    .contact_us_context {
        display: flex;
        justify-content: center;
        margin-bottom: 135px;
        .contact_us_context_text {
            margin: 0 65px 0 78px;
        }
        .contact_us_context_avatar {
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.01em;
    
            .contact_us_context_avatar_logo {
                margin-bottom: 40px;
            }
        }
    }
    .container_comment_box {
        position: relative;
        overflow: hidden;
        padding-bottom: 60px;
        height: 300px;
        .container_comment_list {
            position: absolute;
            height: 200px;
            left: 0;
            top: 0;
            display: flex;
            gap: 20px;
            transition: 1s ease-in-out;
            .container_comment_list_item {
                width: 360px;
                height: 200px;
                background: #222222;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 30px;
            }
        }
        .container_comment_bg {
            position: absolute;
            right: 0;
            top: 0;
            width: 140px;
            height: 200px;
            background: linear-gradient(270deg, #161718 0%, rgba(22, 23, 24, 0) 100%);
         }
         .homePage_type2_btn_box {
            position: absolute;
            display: flex;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            gap: 20px;
            .homePage_type2_btn {
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                color: #fff;
                font-weight: bolder;
                cursor: pointer;
                &.homePage_type2_btn_right {
                    box-sizing: border-box;
                    padding-left: 2px;
                }
                &.cursor_no-drop {
                    cursor: no-drop;
                    opacity: .5;
                }
            }
        }
        .container_comment_item_title {
            display: flex;
            margin-bottom: 16px;
            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 15px;
            }
            .container_comment_title_big {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.01em;
                text-transform: capitalize;
            }
            .container_comment_title_small {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #999;
            }
            .container_comment_title_icon {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.01em;
                text-transform: capitalize;
            }
        }
        .container_comment_item_content {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.01em;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }
    }
    .container_form_box {
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        background: #222222;
        height: 800px;
        display: flex;
        .container_form_box_right {
            flex: 1;
            padding: 50px 80px;
            .container_form_title {
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: 0.01em;
                margin-bottom: 10px;
            }
            .container_form_email {
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0.01em;
            }
            .container_form_introduce {
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #666;
            }
            .ant-input {
                background: rgba(255, 255, 255, 0.05) !important;
                border-radius: 30px;
                padding: 17px 20px;
                color: #eee;
                &::placeholder {
                    color: #999;
                }
            }
            .ant-form-item-explain {
                width: fit-content;
                position: absolute;
                top: -48px;
                line-height: 40px;
                right: 0;
            }
            .ant-form-item {
                margin-bottom: 10px;
                .ant-form-item-label >label {
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                        content: "";
                    }
                }
                .ant-form-item-explain-error {
                    color: #ff8666;
                    font-size: 12px;
                }
                
                .ant-input-outlined.ant-input-status-error {
                    border-color: #ff8666;
                }
                
                .ant-input-outlined {
                    border-color: #666;
                    &:focus {
                        border-color: #ccc;
                    }
                }
            }
            .container_form_btn {
                width: 100%;
                border-radius: 30px;
                height: 50px;
                background: #4E73F8;
                margin-bottom: 15px;
                box-shadow: none;
            }
            .container_form_bg {
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #666;
                a {
                    text-decoration: underline;
                    color: #999;
                }
            }
        }
        .container_form_box_left {
            width: 600px;
            img {
                width: 100%;
            }
        }
    }
    .ant-btn >span {
        font-weight: bold;
        font-size: 14px;
    }
}
@media screen and (max-width: 1280px) {
    .contact_us_title_svg {
        width: 120px;
        height: 54px;
    }
    .contact_us_title {
        text-align: center;
        margin-bottom: 25px;
    }
    .contact_us_wrap {
        overflow: hidden;
        padding: 30px 26px 100px;
    }
    .contact_us_context {
        // margin-bottom: 135px;
        .contact_us_context_text {
            width: 100%;
            height: auto;
            margin: 0 auto;
        }
        .contact_us_context_avatar {
            display: flex;
            text-align: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.01em;
            margin-bottom: 20px;
    
            .contact_us_context_avatar_logo {
                width: 50px;
                height: 50px;
            }
            .contact_us_context_avatar_right {
                display: flex;
                text-align: right;
                align-items: center;
                gap: 14px;
                .contact_us_context_avatar_title1 {
                    font-size: 14px;
                    line-height: 24px;
                }
                .contact_us_context_avatar_title2 {
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 400;
                }
            }
        }
    }
    .dashed_line {
        margin-bottom: 38px;
        &.mb-30 {
            margin-bottom: 15px !important;
        }
    }
    .container_comment_box {
        position: relative;
        overflow-y: scroll;
        height: 200px;
        margin: 0 -26px 50px;
        
        .adm-swiper-track {
            padding:0 13px 0 26px;
            box-sizing: border-box;
        }
        .adm-swiper-indicator {
            display: none;
        }
        .adm-swiper-slide {
            padding-right: 20px;
            box-sizing: border-box;
        }
        .container_comment_list_item {
            height: 200px;
            background: #222222;
            border-radius: 30px;
            box-sizing: border-box;
            padding: 30px;
        }
         .homePage_type2_btn_box {
            position: absolute;
            display: flex;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
            gap: 20px;
            .homePage_type2_btn {
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                color: #fff;
                font-weight: bolder;
                cursor: pointer;
                &.homePage_type2_btn_right {
                    box-sizing: border-box;
                    padding-left: 2px;
                }
                &.cursor_no-drop {
                    cursor: no-drop;
                    opacity: .5;
                }
            }
        }
        .container_comment_item_title {
            display: flex;
            margin-bottom: 16px;
            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 15px;
            }
            .container_comment_title_big {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.01em;
                text-transform: capitalize;
            }
            .container_comment_title_small {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #999;
                white-space: nowrap;
            }
            .container_comment_title_icon {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.01em;
                text-transform: capitalize;
            }
        }
        .container_comment_item_content {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.01em;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }
    }
    .container_form_box {
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        background: #222222;
        height: auto;
        display: flex;
        .container_form_box_right {
            flex: 1;
            padding: 30px 20px;
            .container_form_title {
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.01em;
                margin-bottom: 5px;
            }
            .container_form_email {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;
            }
            .container_form_introduce {
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #666;
                &.mb-30 {
                    margin-bottom: 15px !important;
                }
            }
            .ant-form-item .ant-form-item-label >label {
                color: #fff;
                font-size: 16px;
                font-weight: bold;
            }
            .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                content: "";
            }
            .ant-form-item .ant-form-item-explain-error {
                color: #ff8666;
                font-size: 12px;
            }
            
            .ant-input-outlined.ant-input-status-error {
                border-color: #ff8666;
            }
            .ant-input {
                background: rgba(255, 255, 255, 0.05) !important;
                border-radius: 30px;
                padding: 15px 20px;
                color: #eee;
                &::placeholder {
                    color: #999;
                }
            }
            .ant-input-outlined {
                border-color: #666;
                &:focus {
                    border-color: #ccc;
                }
            }
            .ant-form-item {
                margin-bottom: 10px;
            }
            .ant-form-item-explain {
                width: fit-content;
                position: absolute;
                top: -48px;
                line-height: 40px;
                right: 0;
            }
            .container_form_btn {
                width: 100%;
                border-radius: 30px;
                height: 50px;
                background: #4E73F8;
                margin-bottom: 20px;
                box-shadow: none;
                cursor: none;
                font-weight: 700;
            }
            .container_form_bg {
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #666;
                a {
                    text-decoration: underline;
                    color: #999;
                }
            }
        }
    }
    .container_box_contactUs {
        padding: 50px 0 0;
    }
}