@media screen and (min-width: 1280px) {
    .useDialogCom_box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 628px;
        height: 388px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 30px;
        // border: 4px solid;
        box-sizing: border-box;
        background: linear-gradient(90deg, #FF0000 -8.77%, #FF6400 7.54%, #FFA816 29.3%, #FF9A87 51.05%, #F747D1 78.25%, #AD5DF1 100%);
        padding: 4px;
        transition: 1s ease-in-out;
        // border-image-source: linear-gradient(90deg, #FF0000 -8.77%, #FF6400 7.54%, #FFA816 29.3%, #FF9A87 51.05%, #F747D1 78.25%, #AD5DF1 100%);
        
    }
    .useDialogCom_box_inside {
        width: 100%;
        height: 100%;
        background: #161718;  
        border-radius: 30px;     
        box-sizing: border-box;
        padding: 30px 50px;
        .useDialogCom_box_close {
            position: absolute;
            right: 30px;
            top: 20px;
            color: rgba(153, 153, 153, 1);
            font-weight: 700;
            font-size: 20px;
            cursor: pointer;
        }
        .useDialogCom_box_head {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-bottom: 35px;
            .useDialogCom_box_line {
                width: 0;
                height: 40px;
                border-right: 2px solid rgba(51, 51, 51, 1)
            }
            .useDialogCom_box_title {
                width: fit-content;
                font-size: 20px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: 0.01em;
                background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin-bottom: 3px;
            }
            .useDialogCom_box_title_second {
                font-size: 14px;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: 0.01em;
                .sf_title_textLine {
                    padding-left: 5px;
                }
            }
        }
    }
    .useDialogCom_wrap {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(22, 23, 24, 0.9);
        z-index: 999;
        backdrop-filter: blur(6px);
    }
    .useDialogCom_box_bottom_text {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: rgba(102, 102, 102, 1);
        a {
            text-decoration: underline;
            color: #999;
        }
    }
    .useDialogCom_box_form {
        .ant-form-item-control {
            position: relative;
            .ant-form-item-explain {
                width: fit-content;
                position: absolute;
                right: 0;
                top: -5px;
                transform: translate(0, -100%);
                .ant-form-item-explain-error {
                    width: max-content;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    transform: translate(0, -100%);
                }
            }
        }
        .ant-form-item {
            margin-bottom: 15px;
            &:first-child {
                margin-bottom: 30px;
            }
        }
        .useDialogCom_box_form_first {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            color: #fff;
            height: 60px;
            // border: 1px solid #666;
            border-radius: 30px;
            background: rgba(255, 255, 255, 0.05) !important;
            input:first-child {
                border-right-color: transparent !important;
                border-radius: 30px 0 0 30px;
                margin-right: -8px;
            }
            input:last-child {
                border-left-color: transparent !important;
                border-radius: 0 30px 30px 0;
                margin-left: -8px;
            }
            input:focus {
                border-color: #ccc;
            }
            &:hover {
                border-color: #ccc;
            }
            .ant-input {
                background: transparent !important;
                // border-color: transparent !important;
                &::placeholder {
                    color: rgba(102, 102, 102, 1);
                }
            }
            .useDialogCom_box_form_split {
                white-space: nowrap;
            }
        }
        .ant-input {
            padding: 17px 30px;
            border-radius: 30px;
            color: #fff;
            background: rgba(255, 255, 255, 0.05) !important;
            border-color: rgba(102, 102, 102, 1);
            &:hover {
                border-color: #ccc;
            }
            &::placeholder {
                color: rgba(102, 102, 102, 1);
            }
        }
        .ant-input-group {
            .ant-input-group-addon {
                padding: 0 12px;
            }
            &:hover {
                .ant-input-group-addon {
                    border-color: #ccc;
                }
            }
        }
        .ant-form-item .ant-form-item-explain-error {
            font-size: 12px;
            color: #ff8666;
        }
        .ant-form-item-explain-error {
            padding-left: 30px;
        }
        input.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) {
            border-color: #ff8666;
        }
        .useDialogCom_box_form_second {
            .ant-input-group {
                
                &:hover {
                    border-color: #ccc;
                    .ant-input {
                        border-color: #ccc;
                    }
                }
            }
            .ant-input {
                border-right-color: transparent !important;
            }
            .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
                border-color: #ff8666;
            }
            .ant-input-group-wrapper-status-error {
                .ant-input-group-addon {
                    border-color: #ff8666;
                }
            }
            // .ant-input-status-error + span {
            //     border-color: #ff4d4f;
            // }
            .ant-btn-primary {
                padding: 4px 26px;
                background: #4e73f8;
                box-shadow: none;
            }
            .ant-input-group-addon {
                background: rgba(255, 255, 255, 0.05) !important;
                position: relative;
                // left: -1px;
                border-left-color: transparent !important;
                border-radius: 0 30px 30px 0;
                border-color: rgba(102, 102, 102, 1);
                
                &:focus {
                    color: #ccc;
                }
                .ant-btn  {
                    height: 43px;
                    border-radius: 30px;
                    position: relative;
                    right: -5px;
                }
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .useDialogComPhone_wrap {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background: rgba(22, 23, 24, 0.9);
        padding: 15px;
        box-sizing: border-box;
        backdrop-filter: blur(6px);
    }
    .useDialogCom_box {
        position: absolute;
        transition: ease-in-out 1s;
        left: 50%;
        bottom: 60px;
        transform: translate(-50%, 0);
        width: calc(100% - 30px);
        height: auto;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 30px;
        // border: 4px solid;
        box-sizing: border-box;
        background: linear-gradient(90deg, #FF0000 -8.77%, #FF6400 7.54%, #FFA816 29.3%, #FF9A87 51.05%, #F747D1 78.25%, #AD5DF1 100%);
        padding: 4px;
        // border-image-source: linear-gradient(90deg, #FF0000 -8.77%, #FF6400 7.54%, #FFA816 29.3%, #FF9A87 51.05%, #F747D1 78.25%, #AD5DF1 100%);
        
    }
    .useDialogCom_box_inside {
        width: 100%;
        height: 100%;
        background: rgba(17, 17, 17, 1);  
        border-radius: 30px;     
        box-sizing: border-box;
        padding: 20px;
        .useDialogCom_box_close {
            position: absolute;
            right: 30px;
            top: 20px;
            color: rgba(153, 153, 153, 1);
            font-weight: 700;
            font-size: 20px;
            cursor: pointer;
        }
        .useDialogCom_box_head {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 20px;
            .useDialogCom_box_head_logo {
                width: 42px;
                height: 38px;
                position: relative;
                top: -1px;
            }
            .useDialogCom_box_line {
                width: 0;
                height: 32px;
                border-right: 2px solid rgba(51, 51, 51, 1)
            }
            .useDialogCom_box_title {
                width: fit-content;
                font-size: 12px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.01em;
                background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                // margin-bottom: 6px;
            }
            .useDialogCom_box_title_second {
                font-size: .625rem;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.01em;
                .sf_title_textLine {
                    padding-left: 5px;
                }
            }
        }
        .sf_title_textLine {
            position: relative;
            &::after {
                position: absolute;
                bottom: 0px;
                left: 5px;
                width: 100%;
                height: 1px;
                content: "";
                background: linear-gradient(90deg, #FF0000 0%, rgba(255, 100, 0, 0.964706) 15%, #FFA816 35%, #FF9A87 55%, #F747D1 80%, #AD5DF1 100%);
            }
        }
    }
    .useDialogCom_box_bottom_text {
        // font-size: 10px;
        // font-weight: 400;
        // line-height: 16px;
        // letter-spacing: 0.01em;
        color: rgba(102, 102, 102, 1);
        font-family: 'Microsoft YaHei';
        font-size: .5625rem;
        font-weight: 400;
        line-height: 14px;
        // letter-spacing: 0.01em;
        text-align: center;
        text-underline-position: "from-font";
        text-decoration-skip-ink: none;

        a {
            text-decoration: underline;
            color: #999;
        }
    }
    .useDialogCom_box_form {
        .ant-form-item-control {
            height: 64px;
        }

        .ant-form-item-control {
            position: relative;
            .ant-form-item-explain {
                width: fit-content;
                position: absolute;
                right: 0;
                top: -5px;
                transform: translate(0, -100%);
                .ant-form-item-explain-error {
                    width: max-content;
                    position: absolute;
                    right: 10px;
                    top: 4px;
                    transform: translate(0, -100%);
                }
            }
        }
        .useDialogCom_box_form_first {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            color: #fff;
            height: 45px;
            // border: 1px solid #666;
            border-radius: 30px;
            background: rgba(255, 255, 255, 0.05) !important;
            input:first-child {
                border-right-color: transparent !important;
                border-radius: 30px 0 0 30px;
                margin-right: -8px;
            }
            input:last-child {
                border-left-color: transparent !important;
                border-radius: 0 30px 30px 0;
                margin-left: -8px;
            }
            input:focus {
                border-color: #ccc;
            }
            &:hover {
                border-color: #ccc;
            }
            .ant-input {
                background: transparent !important;
                // border-color: transparent !important;
                &::placeholder {
                    color: rgba(102, 102, 102, 1);
                }
            }
            .useDialogCom_box_form_split {
                white-space: nowrap;
            }
        }
        .ant-form-item {
            margin-bottom: 0;
            &:last-child {
                margin-bottom: 15px;
                .ant-form-item-control {
                    height: 45px;
                }
            }
        }
        .ant-input {
            padding: 11px 20px;
            border-radius: 30px;
            color: #fff;
            background: rgba(255, 255, 255, 0.05) !important;
            border-color: rgba(102, 102, 102, 1);
            &:hover {
                border-color: #ccc;
            }
            &::placeholder {
                color: rgba(102, 102, 102, 1);
            }
        }
        .ant-input-group {
            &:hover {
                .ant-input-group-addon {
                    border-color: #ccc;
                }
            }
        }
        .ant-form-item .ant-form-item-explain-error {
            font-size: 10px;
            color: #ff8666;
        }
        .ant-form-item-explain-error {
            padding-left: 30px;
        }
        input.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) {
            border-color: #ff8666;
        }
        .ant-btn-primary {
            width: 100%;
            height: 45px;
            background: #4e73f8;
            border-radius: 45px;
            box-shadow: none;
            cursor: none;
            font-weight: 700;
        }
        .useDialogCom_box_form_second {
            .ant-input-group {
                
                &:hover {
                    border-color: #ccc;
                    .ant-input {
                        border-color: #ccc;
                    }
                }
            }
            // .ant-input {
            //     border-right-color: transparent !important;
            // }
            .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
                border-color: #ff8666;
            }
            .ant-input-group-wrapper-status-error {
                .ant-input-group-addon {
                    border-color: #ff8666;
                }
            }
            // .ant-input-status-error + span {
            //     border-color: #ff4d4f;
            // }
            .ant-input-group-addon {
                background: rgba(255, 255, 255, 0.05) !important;
                position: relative;
                // left: -1px;
                border-left-color: transparent !important;
                border-radius: 0 30px 30px 0;
                border-color: rgba(102, 102, 102, 1);
                
                &:focus {
                    color: #ccc;
                }
                .ant-btn  {
                    height: 43px;
                    border-radius: 30px;
                    position: relative;
                    right: -5px;
                }
            }
        }
    }
}