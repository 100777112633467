@media screen and (min-width: 1280px) {
    .sf_pricing_container {
        width: 1280px;
        margin: 0 auto 200px;
        .sf_pricing_logo {
            text-align: center;
            margin-bottom: 80px;
        }
        .sf_pricing_container_card {
            display: flex;
            justify-content: space-between;
            .sf_pricing_card {
                width: 620px;
                background: #222222;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 40px 50px;
                color: #fff;
                height: 860px;
                letter-spacing: 0.01em;
                font-style: normal;
                .sf_pricing_card_title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 26px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                }
                .sf_pricing_card_article_text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: 0.01em;
                    // text-transform: capitalize;
                    color: #FFFFFF;
                    margin-bottom: 20px;

                }
                .sf_pricing_card_article {
                    .sf_pricing_card_article_text {
                        margin-bottom: 5px;
                    }
                }
                .pricing_article_1 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        font-size: 50px;
                        font-weight: 700;
                        line-height: 66px;
                        letter-spacing: 0.01em;
                        margin-right: 5px;
                    }
                }
                .pricing_article_2 {
                    color: #999;
                }
                .pricing_article_3 {
                    color: #2FEF77;
                }
                .sf_pricing_card_content {
                    .pricing_card_content_title_list {
                        margin-bottom: 10px;
                    }
                    .pricing_card_content_title_list_item_title {
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                    .pricing_card_content_title_list_item {
                        display: flex;
                    }
                    .pricing_card_content_left {
                        margin-right: 15px;
                    }
                    .pricing_card_content_right {
                        line-height: 18px;
                        .pricing_card_content_title_list_item_content {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.01em;
                            color: #999;
                        }
                    }
                }
                .pricing_card_introduce {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.01em;
                    color: #666666;
                }
                .sf_pricing_card_btn {
                    width: 140px;
                    height: 45px;
                    border-radius: 30px;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 45px;
                    user-select: none;
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: none;
                    transition: .1 ease-in-out;
                    &:hover {
                        background: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .sf_pricing_container {
        width: 100%;
        margin: 0 auto 100px;
        padding-top: 30px !important;
        padding: 0 26px;
        box-sizing: border-box;
        .sf_pricing_logo_svg {
            width: 120px;
            height: 54px;
        }
        .sf_pricing_logo {
            text-align: center;
            margin-bottom: 25px;
        }
        .sf_pricing_container_card {
            .dashed_line_333 {
                margin-bottom: 15px !important;
            }
            .sf_pricing_card {
                width: 100%;
                background: #222222;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 30px 20px;
                color: #fff;
                // height: 820px;
                letter-spacing: 0.01em;
                font-style: normal;
                margin-bottom: 30px;
                .sf_pricing_card_title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 26px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                }
                .sf_pricing_card_article_text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.01em;
                    // text-transform: capitalize;
                    color: #FFFFFF;
                    &.mb-30 {
                        margin-bottom: 15px !important;
                    }
                }
                .sf_pricing_card_article {
                    .sf_pricing_card_article_text {
                        margin-bottom: 5px;
                    }
                }
                .pricing_article_1 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        font-size: 30px;
                        font-weight: 700;
                        line-height: 66px;
                        letter-spacing: 0.01em;
                        margin-right: 5px;
                    }
                }
                .pricing_article_2 {
                    color: #999;
                }
                .pricing_article_3 {
                    color: #2FEF77;
                }
                .sf_pricing_card_content {
                    .pricing_card_content_title_list {
                        margin-bottom: 10px;
                    }
                    .pricing_card_content_title_list_item_title {
                        font-weight: 700;
                        margin-bottom: 3px;
                    }
                    .pricing_card_content_title_list_item {
                        display: flex;
                        &.mb-20 {
                            margin-bottom: 10px;
                        }
                    }
                    .pricing_card_content_left {
                        margin-right: 15px;
                    }
                    .pricing_card_content_right {
                        line-height: 18px;
                        font-size: 14px;
                        .pricing_card_content_title_list_item_content {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.01em;
                            color: #999;
                        }
                    }
                }
                .pricing_card_introduce {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.01em;
                    color: #666666;
                }
                .sf_pricing_card_btn {
                    width: 120px;
                    height: 36px;
                    border-radius: 30px;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 36px;
                    user-select: none;
                    // cursor: pointer;
                    font-weight: 700;
                    font-size: 12px;
                }
            }
        }
    }
}